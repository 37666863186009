import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    Textarea,
    FormControl,
    Image,
    Text,
    Box,
    useDisclosure
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { useRef, useState } from 'react';
import { RiImageAddFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import SendToGroups from './push_notifications/SendToGroups';

export default function AddSendToGroupNotification({ isOpen, onClose, onOpen }) {
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const { isOpen: isSendToGroupOpen, onOpen: onSendToGroupOpen, onClose: onSendToGroupClose } = useDisclosure();

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
        },
        onSubmit: async (values) => {
            console.log(values);
            onClose(); // Close the modal after submitting
            onSendToGroupOpen(); // Open the SendToGroups modal
        }
    });

    const handleContainerClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'sm', md: '2xl' }}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} color={'white'} bg='primary.400' w='100%'>
                        Create Notification For Send To Group
                    </ModalHeader>
                    <ModalCloseButton color={'white'} onClick={onClose} />

                    <form onSubmit={formik.handleSubmit}>
                        <VStack w={'auto'} alignItems={'start'} mx={4} mt={6}>
                            <FormControl>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    placeholder="Title"
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <FormControl>
                                <Textarea
                                    id="description"
                                    name="description"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    placeholder="Description"
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <Box
                                w={'100%'}
                                h={'200px'}
                                bg='primary.50'
                                mt={4}
                                borderRadius={'lg'}
                                borderStyle="dashed"
                                borderWidth="1px"
                                borderColor="primary.800"
                                onClick={handleContainerClick}
                                cursor="pointer"
                                position="relative"
                                overflow="hidden"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    accept="image/*"
                                />
                                {selectedImage ? (
                                    <>
                                        <Image
                                            src={selectedImage}
                                            alt="Selected"
                                            objectFit="contain"
                                            maxW="100%"
                                            maxH="100%"
                                        />
                                        <Button
                                            position="absolute"
                                            top="5px"
                                            right="5px"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveImage();
                                            }}
                                            size="sm"
                                            colorScheme="red"
                                            borderRadius="full"
                                        >
                                            <IoMdClose />
                                        </Button>
                                    </>
                                ) : (
                                    <VStack my={8} color='#F51E9A' spacing={1}>
                                        <RiImageAddFill size={25} />
                                        <Text fontSize={'xs'}>Upload Your Image Here...</Text>
                                        <Text fontSize={'xs'} fontWeight={'300'} color='#ABABAB'>(Max File Size 50MB)</Text>
                                    </VStack>
                                )}
                            </Box>

                            <ModalFooter w={'100%'} px={16}>
                                <Button
                                    my={4}
                                    type="submit"
                                    bg="secondary"
                                    py='6'
                                    width="full"
                                    borderRadius={'50'}
                                    fontSize={'sm'}
                                >
                                    Create And Send Notification
                                </Button>
                            </ModalFooter>
                        </VStack>
                    </form>
                </ModalContent>
            </Modal>

            <SendToGroups isOpen={isSendToGroupOpen} onClose={onSendToGroupClose} />
        </>
    )
}
