import {
    Card,
    Container,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Image,
    Stack
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import ReportsAndAnalytics from './ReportsAndAnalytics';

export default function Dashboard() {

    return (
        <>
            <Container maxWidth='9xl'>

                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing={4}
                >
                    <Link to={'/user-management'}>
                    <Card mt='5' _hover={{ boxShadow: 'xl' }}>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'start'}>
                                <Image src='/user_management.png' h={55}/>
                                <Text className='custom' fontSize='xl' color='primary.900'>User Management</Text>
                            </VStack>
                        </HStack>
                    </Card>
                    </Link>

                    <Link to={'/subscription-management'}>
                    <Card mt='5' _hover={{ boxShadow: 'xl' }}>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'start'}>
                                <Image src='/subscription_plan.png' h={55}/>
                                <Text className='custom' fontSize='xl' color='primary.900'>Subscription Plan</Text>
                            </VStack>
                        </HStack>
                    </Card>
                    </Link>
                    <Link to={'/virtual-gifts'}>
                    <Card mt='5' _hover={{ boxShadow: 'xl' }}>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'start'}>
                                <Image src='/virtual_gifts.png' h={55}/>
                                <Text className='custom' fontSize='xl' color='primary.900'>Virtual Gifts</Text>
                            </VStack>
                        </HStack>
                    </Card>
                    </Link>

                </SimpleGrid>
                
                 <ReportsAndAnalytics />
            </Container>
        </>
    )
}
