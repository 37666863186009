import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

const ReferralContext = createContext();

const ReferralProvider = ({ children }) => {
    const [totalCountReferral, setTotalCountReferral] = useState(0);
    const [referrals, setReferrals] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [usedreferralUsers, setUsedreferralUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    async function getAllReferrals(page = 1, limit = 15, search = '') {
        console.log("Get All Referrals");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            const { data } = await axios.get(`${url}/admin/referrals`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                params: { page, limit, search }
            });
            setReferrals(data.data);
            setTotalCountReferral(data.total);
            setCurrentPage(data.page);
            setTotalPages(data.pages);
            setLoading(false);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            setLoading(false);
            return "err";
        }
    }

    async function getAllUsedReferrals(id) {
        console.log("Get All Used Referrals");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            const { data } = await axios.get(`${url}/admin/referrals/used/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setUsedreferralUsers(data.data);
            setLoading(false);
        } catch (error) {
            setUsedreferralUsers([]);
            console.error(error);
            if (error.response && error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            setLoading(false);
            return "err";
        }
    }

    useEffect(() => {
        getAllReferrals();
    }, [])

    const contextValue = useMemo(
        () => ({
            loading,
            referrals,
            totalCountReferral,
            usedreferralUsers,
            currentPage,
            totalPages,
            getAllReferrals,
            getAllUsedReferrals
        }),
        [loading, referrals, totalCountReferral, usedreferralUsers, currentPage, totalPages]
    );

    return (
        <ReferralContext.Provider value={contextValue}>{children}</ReferralContext.Provider>
    );
};

export const useReferral = () => {
    return useContext(ReferralContext);
};

export default ReferralProvider;