import { Card, Container, Stack, Text, HStack, VStack, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Settings() {
    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize="lg" fontWeight="500">Settings</Text>

            <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={4}
                w={{ base: '100%', md: '50%' }}
            >
                <Link to={'/settings/notification-list'}>
                    <Card mt='5' boxShadow={'lg'} bg='primary.200' _hover={{ boxShadow: 'xl' }}>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'center'} py={4}>
                                <Text className='custom' fontSize='lg' color='primary.900'>Push Notifications</Text>
                            </VStack>
                        </HStack>
                    </Card>
                </Link>

                <Link to={'/settings/your-profile'}>
                    <Card mt='5' boxShadow={'lg'} bg='primary.200' _hover={{ boxShadow: 'xl' }}>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'center'} py={4}>
                                <Text className='custom' fontSize='lg' color='primary.900'>Your Profile</Text>
                            </VStack>
                        </HStack>
                    </Card>
                </Link>
            </SimpleGrid>
        </Container>
    )
}
