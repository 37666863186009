import React, { useEffect } from 'react';
import { Box, Text, VStack, HStack, Container, Card, Stack } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import { FaSquare } from "react-icons/fa";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useUsersAndMatched } from '../../providers/UsersAndMatchedProvider';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function ReportsAndAnalytics() {

    const { usersMatched, loading } = useUsersAndMatched();

    if (loading) {
        return <Text>Loading...</Text>;
    }

    if (!usersMatched || usersMatched.length === 0) {
        return <Text>No data available</Text>;
    }

    const chartData = {
        labels: usersMatched.map(item => item.month),
        datasets: [
            {
                label: 'New Users',
                data: usersMatched.map(item => item.total_users),
                backgroundColor: 'rgba(245, 30, 154, 0.8)',
                borderRadius: 5,
                barThickness: 20,
            },
            {
                label: 'Matched Users',
                data: usersMatched.map(item => item.matched_users),
                backgroundColor: 'rgba(255, 190, 227, 0.8)',
                borderRadius: 5,
                barThickness: 20,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hides Chart.js legend as we use a custom legend
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                    stepSize: 20,
                },
            },
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            },
        },
    };

    return (
        <Card mt={8} p='4'>
            <VStack spacing={4} align="stretch">
                <Stack
                    direction={{ base: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    px={8}
                >
                    <VStack alignItems="start" spacing={0}>
                        <Text fontWeight="800" fontSize={{ base: "lg", sm: "2xl" }}>Reports & Analytics</Text>
                        <Text fontWeight="400" fontSize={{ base: "sm", sm: "md" }} color="gray.500">No of users enrolled and got matched in {new Date().getFullYear()}</Text>
                    </VStack>

                    <HStack spacing={4}>
                        <HStack spacing={2}>
                            <FaSquare color="#F51E9A" />
                            <Text>New users</Text>
                        </HStack>
                        <HStack spacing={2}>
                            <FaSquare color="#FFBEE3" />
                            <Text>Matched users</Text>
                        </HStack>
                    </HStack>
                </Stack>

                <Container maxW="container.xl" mt={4}>
                    <VStack spacing={8} align="stretch">
                        <Box height="400px">
                            <Bar data={chartData} options={options} />
                        </Box>
                    </VStack>
                </Container>
            </VStack>
        </Card>
    );
}