import {
    Button,
    Card,
    Center,
    Container,
    Flex,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiUserAddLine } from "react-icons/ri";
import { LuCalendarDays } from "react-icons/lu";
import { FiCheckSquare } from "react-icons/fi";
import { FiPlusCircle } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { MdMoreHoriz } from "react-icons/md";
import { IoFilter } from "react-icons/io5";
import { useUsers } from '../../../providers/UserProvider';
import { ConfirmDialog } from '../../utils/ConfirmDialog';
import UserLocation from './UserLocation';

export default function UserManagement() {
    const {
        users,
        deleteUser,
        totalUser,
        totalUserThisMonth,
        totalActiveUser,
        banUser,
        activeUser,
        searchUser,
        currentPage,
        totalPages,
        getUsers
    } = useUsers();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);
    const [searchQuery, setSearchQuery] = useState("");

    const handleConfirm = async () => {
        try {
            await deleteUser(selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Prevents breaking words
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    let formattedJoinedDate;

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        if (searchQuery) {
            searchUser(searchQuery);
        } else {
            getUsers(undefined, currentPage);  // Use currentPage here for proper pagination
        }
    }, [searchQuery]);


    const cancelRef = useRef();

    const banUserFunction = async (userId) => {
        await banUser(userId);
    }

    const activeUserFunction = async (userId) => {
        await activeUser(userId);
    }

    return (
        <Container maxWidth='9xl'>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                w={'100%'}
            >
                <Stack
                    w={{ md: '70%' }}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: 2, md: 4 }}
                    alignItems={{ base: 'center', md: 'start' }}
                >
                    <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '70%', md: '25%' }} h='100%'>
                        <VStack spacing={0} alignItems={'start'}>
                            <RiUserAddLine size={28} color='#F51E9A' />
                            <Text className='custom' fontSize='2xl' color='primary.800'>{totalUser.count}</Text>
                            <Text fontSize={'xs'}>Total Users</Text>
                        </VStack>
                    </Card>

                    <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '70%', md: '25%' }} h='100%'>
                        <VStack spacing={0} alignItems={'start'}>
                            <LuCalendarDays size={28} color='#F51E9A' />
                            <Text className='custom' fontSize='2xl' color='primary.800'>{totalUserThisMonth.count}</Text>
                            <Text fontSize={'xs'}>Total Users This Month</Text>
                        </VStack>
                    </Card>

                    <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '70%', md: '25%' }} h='100%'>
                        <VStack spacing={0} alignItems={'start'}>
                            <FiCheckSquare size={28} color='#F51E9A' />
                            <Text className='custom' fontSize='2xl' color='primary.800'>{totalActiveUser.count}</Text>
                            <Text fontSize={'xs'}>Active Users Currently on App</Text>
                        </VStack>
                    </Card>
                </Stack>

                {/* <Container w={{ md: 'auto' }} px={{ base: 16, md: 0 }}>
                    <Link to={'/user-management/create-new-user'}>
                        <HStack
                            p={{ base: '4', md: 6 }}
                            bg={'secondary'}
                            borderRadius={{ base: '22px', md: '22px', lg: '42px' }}
                            width={{ base: '100%', md: 'auto' }}
                            mt={{ base: 4, md: 5 }}
                            justifyContent={'center'}
                        >

                            <FiPlusCircle size={'22px'} />

                            <Text fontSize={{ md: 'sm', lg: 'md' }} fontWeight={'500'}>
                                Create New User
                            </Text>
                        </HStack>
                    </Link>
                </Container> */}

            </Stack>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={4}>
                <Text fontSize={'sm'} fontWeight={'500'}>Below is the list of users in KJ Lemon app</Text>

                <FormControl w={{ base: '100%', md: '60%', lg: '40%' }}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <IoSearch color='#F51E9A' />
                        </InputLeftElement>
                        <Input
                            id="search"
                            name="search"
                            type="text"
                            variant="filled"
                            required={true}
                            placeholder="Search here..."
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            _focus={{
                                borderColor: "primary.800",
                            }}
                        />
                        {/* <InputRightElement pointerEvents="none">
                            <IoFilter size={'18px'} color='#F51E9A' />
                        </InputRightElement> */}
                    </InputGroup>
                </FormControl>

                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                    <Table variant="simple" w='100%'>
                        <Thead>
                            <Tr bg="primary.100">
                                <Th as='th' style={camelCaseStyle} fontWeight={'800'}>User Name</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Phone</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Email ID</Th>
                                {/* <Th width="460px" minWidth="460px" style={camelCaseStyle} fontWeight="800">
                                    Location
                                </Th> */}
                                <Th style={camelCaseStyle} fontWeight={'800'}>Status</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Subscription</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Joined</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {users.map((user, index) => (
                                formattedJoinedDate = formatDate(user.createdAt),
                                <Tr key={index} fontSize="sm">
                                    <Td textDecoration="underline" color='primary.800' fontSize='md'>
                                        <Link to={'/user-management/user-details'} state={user}>
                                            {user.username}
                                        </Link>
                                    </Td>
                                    <Td>{user.phone_number ? user.phone_number : "NA"}</Td>
                                    <Td>{user.email}</Td> 
                                    {/* <UserLocation location={user.location} /> */}
                                    {user.is_banned ?
                                        <Td color={'red'}>In Active</Td>
                                        :
                                        <Td color={'green'}>Active</Td>
                                    }
                                    <Td>{user.subscription_plan}</Td>
                                    <Td>{formattedJoinedDate}</Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton>
                                                <MdMoreHoriz />
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem minH='30px'>
                                                    <Link to={'/user-management/user-details'} state={user}>
                                                        <Text fontSize={'sm'} pl={1}>View User Account</Text>
                                                    </Link>
                                                </MenuItem>

                                                <MenuItem minH='30px'>
                                                    {user.is_banned ?
                                                        <Link onClick={() => activeUserFunction(user._id)}>
                                                            <Text fontSize={'sm'} pl={1}>Active User Account</Text>
                                                        </Link>
                                                        :
                                                        <Link onClick={() => banUserFunction(user._id)}>
                                                            <Text fontSize={'sm'} pl={1}>Suspend User Account</Text>
                                                        </Link>
                                                    }
                                                </MenuItem>

                                                <MenuItem minH='30px'>
                                                    <Link onClick={() => { setSelectedId(user._id); setIsConfirmDialogOpen(true); }}>
                                                        <Text fontSize={'sm'} t pl={1} color={'red'}>Delete User Account</Text>
                                                    </Link>
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            {/* / Pagination/ */}
            <HStack w={'100%'} alignItems={'center'} justifyContent="center" mb='5'>
                <Center>
                    <Flex alignItems='center' gap='2'>
                        {currentPage == 1 || currentPage == 0 ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getUsers(undefined, currentPage - 1)}
                                ml={2}
                            >Previous</Button>
                        }

                        {/* {[...Array(totalPages)].map((_, i) => (
                            <Button
                                key={i}
                                colorScheme={currentPage == i + 1 ? "primary" : "gray"}
                                onClick={() => getUsers(undefined, i + 1)}
                                ml={2}
                            >
                                {i + 1}
                            </Button>
                        ))} */}
                        <Text>{`Page ${currentPage} of ${totalPages}`}</Text>

                        {(totalPages == currentPage || totalPages == 0) ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getUsers(undefined, (parseInt(currentPage) + 1))}
                                ml={2}
                            >Next</Button>
                        }

                    </Flex>
                </Center>
            </HStack>

            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />

        </Container>
    )
}
