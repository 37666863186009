import {
    Button,
    Card,
    Center,
    Container,
    Flex,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoSearch } from "react-icons/io5";
import { IoFilter } from "react-icons/io5";
import { FiEye } from "react-icons/fi";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { useVirtualGift } from "../../../providers/VirtualGiftProvider";
import { ConfirmDialog } from '../../utils/ConfirmDialog';

export default function ManageYourGifts() {

    const { virtualGifts, currentPage, totalPages, getVirtualGift, deleteVirtualGift } = useVirtualGift();

    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Prevents breaking words
    };

    useEffect(() => {
        console.log(virtualGifts)
    })

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const handleConfirm = async () => {
        try {
            await deleteVirtualGift(selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                <Link to='/virtual-gifts'>
                    Virtual Gifts &gt;{' '}
                </Link>
                <Text as="span" color="black" pl={2}>
                    Manage Your Gifts
                </Text>
            </Text>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={6}>

                {/* <FormControl w={{ base: '100%', md: '50%', lg: '35%' }}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <IoSearch size={'18px'} color='#F51E9A' />
                        </InputLeftElement>
                        <Input
                            id="search"
                            name="search"
                            type="text"
                            variant="filled"
                            required={true}
                            placeholder="Search here..."
                            fontSize={{ base: 'xs', md: 'sm' }}
                            py={4}
                            px={10}
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                        />
                        <InputRightElement pointerEvents="none">
                            <IoFilter size={'18px'} color='#F51E9A' />
                        </InputRightElement>
                    </InputGroup>
                </FormControl> */}

                <Text>This is for your information only. To actually reflect the changes , You need to update on Google Play console and Apple App Console.</Text>

                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }} mt={4}>
                    <Table variant="simple" >
                        <Thead>
                            <Tr bg="primary.100">
                                {/* <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">ID</Th> */}
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Virtual Gift Name</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Price</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Status</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {virtualGifts.map((v, index) => (
                                <Tr key={index} fontSize="sm">
                                    {/* <Td textAlign="center">#123</Td> */}
                                    <Td textAlign="center">{v.gift_name}</Td>
                                    <Td textAlign="center">${v.price}</Td>
                                    {v.is_active ?
                                        <Td textAlign="center" color={'green'}>Active</Td>
                                        :
                                        <Td textAlign="center" color={'red'}>In Active</Td>
                                    }
                                    <td textAlign="center">
                                        <HStack justifyContent={'center'}>

                                            <Link to={'/virtual-gifts/manage-your-gifts/view-virtual-gift'} state={v}>
                                                <Card p={2}>
                                                    <FiEye size={18} />
                                                </Card>
                                            </Link>

                                            <Link to={'/virtual-gifts/manage-your-gifts/edit-virtual-gift'} state={v}>
                                                <Card p={2}>
                                                    <MdOutlineEdit color='#F51E9A' size={18} />
                                                </Card>
                                            </Link>

                                            <Link onClick={() => { setSelectedId(v._id); setIsConfirmDialogOpen(true); }}>
                                                <Card p={2}>
                                                    <RiDeleteBin6Line color='red' size={18} />
                                                </Card>
                                            </Link>

                                        </HStack>
                                    </td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />

            {/* / Pagination/ */}
            <HStack w={'100%'} alignItems={'center'} justifyContent="center">
                <Center>
                    <Flex>
                        {/* {currentPage == 1 || currentPage == 0 ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getVirtualGift(currentPage - 1)}
                                ml={2}
                            >Previous</Button>
                        } */}
                        {[...Array(totalPages)].map((_, i) =>
                            <Button
                                key={i}
                                colorScheme={currentPage == i + 1 ? "primary" : "gray"}
                                onClick={() => getVirtualGift(i + 1)}
                                ml={2}
                            >{i + 1}</Button>
                        )}
                        {/* {(totalPages == currentPage || totalPages == 0) ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getVirtualGift(currentPage + 1)}
                                ml={2}
                            >Next</Button>
                        } */}
                    </Flex>
                </Center>
            </HStack>
        </Container>
    )
}

