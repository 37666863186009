import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    Textarea,
    FormControl,
    Image,
    Box,
    Text,
    HStack,
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { useRef, useState } from 'react';
import { RiImageAddFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

export default function CreateNewNotification({ isOpen, onClose, onOpen }) {
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            scheduleDate: "",
            scheduleTime: "",
        },
        onSubmit: async (values) => {
            console.log(values);
            console.log(selectedImage);
            // Handle form submission here
        }
    });

    const handleContainerClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'sm', md: '2xl' }}>
            <ModalOverlay />
            <ModalContent mx={{ base: 4, md: 0 }}>
                <ModalHeader fontSize={'md'} color={'white'} bg='primary.400' w='100%'>
                    Create New Notification
                </ModalHeader>
                <ModalCloseButton color={'white'} />

                <form onSubmit={formik.handleSubmit}>
                    <VStack w={'auto'} alignItems={'start'} mx={4} mt={6} spacing={4}>
                        <FormControl>
                            <Input
                                id="title"
                                name="title"
                                type="text"
                                variant="filled"
                                required={true}
                                placeholder="Title"
                                fontSize="sm"
                                py={4}
                                bg="none"
                                borderColor="gray.100"
                                _focus={{
                                    borderColor: "primary.800",
                                }}
                                borderRadius="10"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                            />
                        </FormControl>

                        <FormControl>
                            <Textarea
                                id="description"
                                name="description"
                                variant="filled"
                                required={true}
                                placeholder="Description"
                                fontSize="sm"
                                py={4}
                                bg="none"
                                borderColor="gray.100"
                                _focus={{
                                    borderColor: "primary.800",
                                }}
                                borderRadius="10"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                            />
                        </FormControl>

                        <HStack w="100%" spacing={4}>
                            <FormControl>
                                <Input
                                    id="scheduleDate"
                                    name="scheduleDate"
                                    type="date"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.scheduleDate}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                            <FormControl>
                                <Input
                                    id="scheduleTime"
                                    name="scheduleTime"
                                    type="time"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.scheduleTime}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </HStack>

                        <Box
                            w={'100%'}
                            h={'200px'}
                            bg='primary.50'
                            mt={4}
                            borderRadius={'lg'}
                            borderStyle="dashed"
                            borderWidth="1px"
                            borderColor="primary.800"
                            onClick={handleContainerClick}
                            cursor="pointer"
                            position="relative"
                            overflow="hidden"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                            {selectedImage ? (
                                <>
                                    <Image
                                        src={selectedImage}
                                        alt="Selected"
                                        objectFit="contain"
                                        maxW="100%"
                                        maxH="100%"
                                    />
                                    <Button
                                        position="absolute"
                                        top="5px"
                                        right="5px"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveImage();
                                        }}
                                        size="sm"
                                        colorScheme="red"
                                        borderRadius="full"
                                    >
                                        <IoMdClose />
                                    </Button>
                                </>
                            ) : (
                                <VStack my={8} color='#F51E9A' spacing={1}>
                                    <RiImageAddFill size={25} />
                                    <Text fontSize={'xs'}>Upload Your Image Here...</Text>
                                    <Text fontSize={'xs'} fontWeight={'300'} color='#ABABAB'>(Max File Size 50MB)</Text>
                                </VStack>
                            )}
                        </Box>

                        <ModalFooter w={'100%'} px={16}>
                            <Button
                                my={4}
                                type="submit"
                                bg="secondary"
                                py='6'
                                width="full"
                                borderRadius={'50'}
                                fontSize={'sm'}
                            >
                                Create Notification
                            </Button>
                        </ModalFooter>
                    </VStack>
                </form>
            </ModalContent>
        </Modal>
    );
}