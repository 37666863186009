import {
    SimpleGrid,
    Box,
    Card,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import AddSendToAllUsersNotification from '../AddSendToAllUsersNotification';
import AddSendToGroupNotification from '../AddSendToGroupNotification';
import AddSendToSpecificUsersNotification from '../AddSendToSpecificUsersNotification';

export default function PushNotification() {
    // export default function PushNotification({ isOpen, onClose }) {

    const { isOpen: isSendToAllUsersOpen, onOpen: onSendToAllUsersOpen, onClose: onSendToAllUsersClose } = useDisclosure();
    const { isOpen: isSendToGroupOpen, onOpen: onSendToGroupOpen, onClose: onSendToGroupClose } = useDisclosure();
    const { isOpen: isSendToSpecificUsersOpen, onOpen: onSendToSpecificUsersOpen, onClose: onSendToSpecificUsersClose } = useDisclosure();

    return (
        <>

            <SimpleGrid columns={[1, 2, 2, 3]} spacing='4'>
                <Box cursor='pointer' onClick={() => onSendToAllUsersOpen()} w='100%' alignItems='center'>
                    <Card bg='primary.200' p='12' w='100%' alignItems={'center'} h='100%' _hover={{ boxShadow: 'xl' }}>
                        <Text className='custom' fontSize='md' color='primary.900'>Send to All Users</Text>
                    </Card>

                </Box>

                <Box cursor='pointer' onClick={() => onSendToGroupOpen()} w='100%' alignItems='center'>
                    <Card bg='primary.200' p='12' w='100%' alignItems={'center'} h='100%' _hover={{ boxShadow: 'xl' }}>
                        <Text className='custom' fontSize='md' color='primary.900'> Send To Groups</Text>
                    </Card>

                </Box>
                <Box cursor='pointer' onClick={() => onSendToSpecificUsersOpen()} w='100%' alignItems='center'>
                    <Card bg='primary.200' p='12' w='100%' alignItems={'center'} h='100%' _hover={{ boxShadow: 'xl' }}>
                        <Text className='custom' fontSize='md' color='primary.900'> Send To Specific Users</Text>
                    </Card>
                </Box>
            </SimpleGrid >

            <AddSendToAllUsersNotification isOpen={isSendToAllUsersOpen} onClose={onSendToAllUsersClose} />
            <AddSendToGroupNotification isOpen={isSendToGroupOpen} onClose={onSendToGroupClose} />
            <AddSendToSpecificUsersNotification isOpen={isSendToSpecificUsersOpen} onClose={onSendToSpecificUsersClose} />
        </>
    )
}


