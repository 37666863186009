import {
    Card,
    Container,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
    Input,
    InputLeftElement,
    FormControl,
    InputGroup,
    Button,
    HStack,
    Box
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiUserAddLine } from "react-icons/ri";
import ReferralsViewList from '../../models/ReferralsViewList';
import { Link } from 'react-router-dom';
import { useReferral } from "../../../providers/ReferralProvider";
import { IoSearch } from "react-icons/io5";

export default function Referrals() {
    const { referrals, totalCountReferral, getAllUsedReferrals, getAllReferrals, currentPage, totalPages, loading } = useReferral();

    const [searchQuery, setSearchQuery] = useState("");
    const { isOpen: isViewListOpen, onOpen: onViewListOpen, onClose: onViewListClose } = useDisclosure();

    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
    };

    useEffect(() => {
        getAllReferrals(1);
    }, []);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
        getAllReferrals(1, 15, e.target.value);
    };

    const handlePageChange = (page) => {
        getAllReferrals(page, 15, searchQuery);
    };

    return (
        <Container maxWidth='9xl'>
            <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '85%', md: '25%', lg: '15%' }} mx={{ base: 6, md: 0 }}>
                <VStack spacing={0} alignItems={'start'}>
                    <RiUserAddLine size={25} color='#F51E9A' />
                    <Text className='custom' fontSize='2xl' color='primary.800'>{totalCountReferral}</Text>
                    <Text fontSize={'xs'}>Total Referrals</Text>
                </VStack>
            </Card>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={4}>
                <FormControl w={{ base: '100%', md: '60%', lg: '40%' }}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <IoSearch color='#F51E9A' />
                        </InputLeftElement>
                        <Input
                            id="search"
                            name="search"
                            type="text"
                            variant="filled"
                            required={true}
                            placeholder="Search by Referral Code or username"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            _focus={{
                                borderColor: "primary.800",
                            }}
                        />
                    </InputGroup>
                </FormControl>

                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                    <Table variant="simple" >
                        <Thead>
                            <Tr bg="primary.100">
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Referral Code</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">UserName</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">No of Referrals</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Uses</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {referrals.map((r, index) => (
                                <Tr key={index} fontSize="sm">
                                    <Td textAlign="center" fontWeight={'600'}>{r.referralCode}</Td>
                                    <Td textAlign="center">
                                        {r.referrerName ? r.referrerName : 'N/A'}
                                    </Td>
                                    <Td textAlign="center">{r.noOfUsedByReferrals}</Td>
                                    <Td textAlign="center" color={'#F51E9A'}>
                                        <Link
                                            onClick={() => {
                                                getAllUsedReferrals(r._id);
                                                onViewListOpen();
                                            }}
                                        >
                                            View List
                                        </Link>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                <Box width="100%" display="flex" justifyContent="center" mt={4}>
                    <HStack spacing={2}>
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            isDisabled={currentPage === 1 || loading}
                        >
                            Previous
                        </Button>
                        <Text>{`Page ${currentPage} of ${totalPages}`}</Text>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            isDisabled={currentPage === totalPages || loading}
                        >
                            Next
                        </Button>
                    </HStack>
                </Box>
            </VStack>
            <ReferralsViewList isOpen={isViewListOpen} onClose={onViewListClose} />
        </Container>
    )
}