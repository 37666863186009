import {
    Box,
    Button,
    Container,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Stack,
    Text,
    VStack,
    Textarea,
    FormLabel,
    Image
} from '@chakra-ui/react';
import React from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { BiPhoneCall } from "react-icons/bi";
import { LiaEditSolid } from "react-icons/lia";
import { PiCakeFill } from "react-icons/pi";
import { useUsers } from '../../../providers/UserProvider';
import UserLocation from './UserLocation';

export default function UserDetails() {
    const { banUser, activeUser } = useUsers();
    const { state } = useLocation();

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formattedDateOfBirth = state.date_of_birth ? formatDate(state.date_of_birth) : "NA";

    const formik = useFormik({
        initialValues: {
            f_name: state.first_name || "NA",
            l_name: state.last_name || "NA",
            phone: state.phone_number || "NA",
            email: state.email || "NA",
            dob: { formattedDateOfBirth } || "NA",
            // old: "25 years",
            // dob: "22/04/1998",
            bio: state.bio || "NA",
            sexual_orientation: state.sexual_orientation || "NA",
            // location: <UserLocation location={state.location} /> || "NA",
            interest_hobbies: state.hobbies || "NA",
        },
        onSubmit: async (values) => {
            console.log(values);
        }
    });

    const banUserFunction = async (userId) => {
        await banUser(userId);
    }

    const activeUserFunction = async (userId) => {
        await activeUser(userId);
    }

    return (
        <Container maxWidth='9xl' px={6}>
            <Stack direction={{ base: 'column', md: 'row' }} w={'100%'} justifyContent={'space-between'}>
                <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                    <Link to='/user-management'>
                        User Management &gt;{' '}
                    </Link>
                    <Text as="span" color="black" pl={2}>
                        {state.first_name}
                    </Text>
                    <Text as="span" color="black" pl={2}>
                        {state.last_name}
                    </Text>
                </Text>

                {/* <Menu>
                    <MenuButton>
                        <Box
                            mt={{ base: 4, md: 0 }}
                            px={4}
                            py={2}
                            border={'1px'}
                            borderRadius={'md'}
                            borderColor={'#F2F2F2'}
                        >
                            <HStack justifyContent={{ base: 'space-between' }}>
                                <Text fontSize={'sm'}>More Options</Text>
                                <IoMdArrowDropdown color='#F51E9A' size={25} />
                            </HStack>
                        </Box>
                    </MenuButton>
                    <MenuList >
                        <MenuItem minH='30px'>
                            {state.is_banned ?
                                <Link onClick={() => activeUserFunction(state._id)}>
                                    <Text fontSize={'sm'} pl={1}>Active User Account</Text>
                                </Link>
                                :
                                <Link onClick={() => banUserFunction(state._id)}>
                                    <Text fontSize={'sm'} pl={1}>Suspend User Account</Text>
                                </Link>
                            }
                        </MenuItem>

                        <MenuItem minH='30px'>
                            <Link to={'/#'}>
                                <Text pl={1} fontSize={'sm'} color={'red'}>Delete Account</Text>
                            </Link>
                        </MenuItem>
                    </MenuList>
                </Menu> */}
            </Stack>

            <Box
                mt={4}
                // border="1px"
                // borderColor="#F2F2F2"
                borderRadius="md"
                // w={{ base: '100%', lg: '80%' }}
                py={{ base: 4, md: 0 }}
            >
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    alignItems="center"
                    spacing={{ base: 6, md: 20 }}
                >
                    <Button
                        py={{ base: 6, md: 8 }}
                        type="submit"
                        bg="primary.800"
                        width="full"
                        borderRadius="12"
                        fontSize="sm"
                        color="white"
                    >
                        User Details
                    </Button>

                    {/* <Link to={'/user-management/preference-details'} state={state}>
                        <Text textAlign={'center'}>
                            Preference Details
                        </Text>
                    </Link>

                    <Link to={'/user-management/matches-details'} state={state}>
                        <Text textAlign={'center'}>
                            Matches
                        </Text>
                    </Link> */}
                </SimpleGrid>
            </Box>

            <Stack direction={{ base: 'column', md: 'row' }} mt={8} w={'100%'} spacing={12}>
                <VStack spacing={0} ml={{ md: 16 }}>
                    {state.profile_picture ?
                        <Image
                            src={state.profile_picture}
                            alt='user'
                            boxSize="120px"
                            borderRadius="full"
                        />
                        :
                        <img src='../user_management.png' width={'120px'} alt='user' />
                    }
                    <Text fontSize="xl" fontWeight="800" color="primary.800">
                        {state.first_name}
                        <Text as="span" pl={2}>
                            {state.last_name}
                        </Text>
                    </Text>
                    <Text fontSize="sm">{state.email}</Text>
                </VStack>

                <form onSubmit={formik.handleSubmit}>
                    <VStack w={'100%'} alignItems={'start'} ml={{ md: 12 }}>
                        <HStack w={'100%'} justifyContent={'space-between'}>
                            <Text className='custom' fontSize={'sm'} pb={1}>Basic Details</Text>
                            {/* <LiaEditSolid color="#F51E9A" size={20} /> */}
                        </HStack>
                        <SimpleGrid w={'100%'} columns={{ base: 1, lg: 2 }} spacing={3}>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="f_name"
                                        name="f_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "grey.100",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.f_name}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="l_name"
                                        name="l_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "grey.100",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.l_name}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<BiPhoneCall color="#F51E9A" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "grey.100",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<IoIosMail color="#F51E9A" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "grey.100",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<PiCakeFill color="#F51E9A" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="dob"
                                        name="dob"
                                        type="text"
                                        variant="filled"
                                        placeholder='NA'
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "grey.100",
                                        }}
                                        borderRadius="10"
                                        value={formattedDateOfBirth}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                        </SimpleGrid>

                        <FormControl mt={2}>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                    mt={2}
                                />
                                <Textarea
                                    id="bio"
                                    name="bio"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    // placeholder="Your Bio"
                                    fontSize="sm"
                                    py={4}
                                    px={10}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "grey.100",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.bio}
                                    onChange={formik.handleChange}
                                    isReadOnly
                                />
                            </InputGroup>
                        </FormControl>

                        <Stack w={'100'} direction={{ base: 'column', md: 'row' }} mt={4}>
                            <FormControl>
                                <FormLabel className='custom' fontSize={'sm'}>Sexual Orientation</FormLabel>
                                <Input
                                    id="sexual_orientation"
                                    name="sexual_orientation"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    py={6}
                                    px={10}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "grey.100",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.sexual_orientation}
                                    onChange={formik.handleChange}
                                    isReadOnly
                                />
                            </FormControl>

                            {/* <FormControl>
                                <FormLabel className='custom' fontSize={'sm'}>Location</FormLabel>
                                <Input
                                    id="location"
                                    name="location"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    py={6}
                                    px={10}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "grey.100",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    isReadOnly
                                />
                            </FormControl> */}
                        </Stack>

                        <FormControl>
                            <FormLabel className='custom' fontSize={'sm'}>Interest & Hobbies</FormLabel>
                            {state.hobbies ?
                                <HStack
                                    spacing={4}
                                    border={'2px'}
                                    borderColor="gray.100"
                                    borderRadius="10"
                                    py={4}
                                    px={4}
                                >
                                    {state.hobbies.map((hobby, index) => (
                                        <Text key={index} fontSize="md">
                                            ■ {hobby}
                                        </Text>
                                    ))}
                                </HStack>
                                :
                                <HStack
                                    spacing={4}
                                    border={'2px'}
                                    borderColor="gray.100"
                                    borderRadius="10"
                                    py={4}
                                    px={4}>
                                    <Text fontSize="md">NA</Text>
                                </HStack>
                            }
                        </FormControl>

                        <Text mt={4} className='custom' fontSize={'sm'}> Photo & Videos</Text>

                        <HStack
                            spacing={3}
                            border={'2px'}
                            borderColor="gray.100"
                            borderRadius="10"
                            py={4}
                            px={4}
                        >
                            {state.photos.map((p, index) => (
                                <Image
                                    key={index}
                                    src={p}
                                    boxSize="120px"
                                    alt='photo'
                                />
                            ))}
                        </HStack>

                        {/* <Container mb={4}>
                            <Button
                                mt={6}
                                type="submit"
                                bg="secondary"
                                py='6'
                                width="full"
                                borderRadius={'50'}
                                fontSize={'sm'}
                            >
                                Save Your Profile
                            </Button>
                        </Container> */}

                    </VStack>
                </form>
            </Stack>
        </Container>
    )
}
