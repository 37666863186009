import {
    Button,
    Card,
    Center,
    Container,
    Flex,
    HStack,
    Text,
    VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSupportTickets } from "../../../providers/SupportTicket";
import { Link } from 'react-router-dom';
import TicketReply from './TicketReply';

export default function Resolved() {

    const [selectedTicket, setSelectedTicket] = useState(null);
    const [isTicketReplyOpen, setIsTicketReplyOpen] = useState(false);

    const onTicketReplyOpen = (ticket) => {
        setSelectedTicket(ticket);
        setIsTicketReplyOpen(true);
    };

    const onTicketReplyClose = () => {
        setSelectedTicket(null);
        setIsTicketReplyOpen(false);
    };

    const {
        totalTicket,
        totalUnresolvedTicket,
        totalResolvedTicket,
        resolvedTickets,
        resolvedCurrentPage,
        totalResolvedPages,
        getResolvedTicket
    } = useSupportTickets();

    useEffect(() => {
        console.log(resolvedTickets)
    })

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    let formattedJoinedDate;

    return (
        <Container maxWidth='9xl' px={6}>

            <Text className='custom' fontSize="lg" fontWeight="500">
                Ticket Management
            </Text>

            <VStack w={{ lg: '80%' }} mt={8} mb={4}>
                <HStack w={'100%'} spacing={8} mb={4}>

                    <Link to={'/ticket-management'}>
                        <Text color={'grey'} fontWeight={'500'}>All Tickets ({totalTicket})</Text>
                    </Link>

                    <Link to={'/ticket-management/unresolved'}>
                        <Text color={'grey'} fontWeight={'500'}> Unresolved ({totalUnresolvedTicket})</Text>
                    </Link>

                    <Text color='primary.800' px={2} fontWeight={'600'} borderBottom="3px solid #F51E9A">
                        Resolved ({totalResolvedTicket})
                    </Text>
                </HStack>

                {resolvedTickets.map((t, index) => (
                    formattedJoinedDate = formatDate(t.created_at),
                    <Card _hover={{ boxShadow: 'xl' }} key={index} py={4} px={4} my={1} w={'100%'} onClick={() => onTicketReplyOpen(t)}>
                        <VStack alignItems={'start'} spacing={1}>
                            <HStack w={'100%'} justifyContent={'space-between'}>
                                <Text fontWeight={'600'} color='primary.800'>{t.title}</Text>
                                <Text fontSize={'sm'} color='primary.800'>{formattedJoinedDate}</Text>
                            </HStack>
                            <Text fontSize={'sm'}>
                                {t.description}
                            </Text>

                            <HStack mt={4} spacing={6}>
                                <Text fontWeight={'500'}>
                                    Status:
                                    <Text
                                        as="span"
                                        fontSize={'sm'}
                                        color={
                                            t.status == 'Closed' ? 'green.500'
                                                : t.status == 'Open' ? 'blue.500'
                                                    : t.status == 'Inprocess' ? 'yellow.500'
                                                        : 'gray.500'
                                        }
                                        pl={1}
                                    >
                                        {t.status}
                                    </Text>
                                </Text>

                                {/* <Text fontWeight={'500'}>
                                    Handled By:
                                    <Text as="span" fontSize={'sm'} color="grey" pl={1}>
                                        John Doe
                                    </Text>
                                </Text> */}
                            </HStack>
                        </VStack>
                    </Card>
                ))}

            </VStack>

            {/* / Pagination/ */}
            {/* <HStack w={'100%'} alignItems={'center'} justifyContent="center">
                <Center>
                    <Flex>
                        {resolvedCurrentPage === 1 || resolvedCurrentPage === 0 ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getResolvedTicket(resolvedCurrentPage - 1)}
                                ml={2}
                            >Previous</Button>
                        }
                        {[...Array(totalResolvedPages)].map((_, i) =>
                            <Button
                                key={i}
                                colorScheme={resolvedCurrentPage === i + 1 ? "primary" : "gray"}
                                onClick={() => getResolvedTicket(i + 1)}
                                ml={2}
                            >{i + 1}</Button>
                        )}
                        {(totalResolvedPages === resolvedCurrentPage || totalResolvedPages === 0) ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getResolvedTicket(resolvedCurrentPage + 1)}
                                ml={2}
                            >Next</Button>
                        }
                    </Flex>
                </Center>
            </HStack> */}

            {isTicketReplyOpen && (
                <TicketReply
                    isOpen={isTicketReplyOpen}
                    onClose={onTicketReplyClose}
                    ticketDetails={selectedTicket}
                />
            )}
        </Container>
    )
}

