import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Box, HStack, VStack, useBreakpointValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import SidebarLinks from '../common/SidebarLinks';
import AdminProvider from '../../providers/AdminProvider';
import FaqProvider from '../../providers/FaqProvider';
import UserProvider from '../../providers/UserProvider';
import { useAuth } from '../../providers/AuthProvider';
import RatingsProvider from '../../providers/RatingsProvider';
import VirtualGiftProvider from '../../providers/VirtualGiftProvider';
import SupportTicketProvider from '../../providers/SupportTicket';
import NotificationGroupProvider from '../../providers/NotificationGroupProvider';
import SubscriptionFeatureProvider from '../../providers/SubscriptionFeatureProvider';
import ReferralProvider from '../../providers/ReferralProvider';
import UsersAndMatchedProvider from '../../providers/UsersAndMatchedProvider';

export default function PrivateRoutes() {
    const { loading, logged } = useAuth();
    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    // let loading = false;
    // let logged = true;


    if (loading) {
        return (
            <React.Fragment>
                <p>Loading...</p>
            </React.Fragment>
        )
    }

    return (
        logged ?
            <AdminProvider>
                <FaqProvider>
                    <UserProvider>
                        <RatingsProvider>
                            <VirtualGiftProvider>
                                <SupportTicketProvider>
                                    <NotificationGroupProvider>
                                        <SubscriptionFeatureProvider>
                                            <ReferralProvider>
                                                <UsersAndMatchedProvider>
                                                    <HStack spacing='0'>
                                                        {sideBarVariant === 'sidebar' && <SidebarLinks />}
                                                        <Box h='100vh' w='100%' overflowY='auto'>
                                                            <VStack>
                                                                <Navbar />
                                                                <Outlet />
                                                            </VStack>
                                                        </Box>
                                                    </HStack>
                                                </UsersAndMatchedProvider>
                                            </ReferralProvider>
                                        </SubscriptionFeatureProvider>
                                    </NotificationGroupProvider>
                                </SupportTicketProvider>
                            </VirtualGiftProvider>
                        </RatingsProvider>
                    </UserProvider>
                </FaqProvider>
            </AdminProvider>
            : <Navigate to='/login' />
    )
}