import {
    Modal,
    ModalOverlay,
    ModalContent,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Text,
    HStack,
    Card,
    Image
} from '@chakra-ui/react';
import { useReferral } from "../../providers/ReferralProvider";

export default function ReferralsViewList({ isOpen, onClose }) {

    const { usedreferralUsers } = useReferral();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={'lg'} >
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>

                    <ModalHeader fontSize={'md'} color={'white'} bg='primary.400' w='100%'>
                        View List
                    </ModalHeader>

                    <ModalCloseButton color={'white'} />

                    {usedreferralUsers.length > 0 ? (
                        <VStack spacing={2} maxH="50vh" overflowY="auto" py={4} px={8}>
                            {usedreferralUsers.map((r, index) => (
                                <Card key={index} p={2} w={'100%'}>
                                    <HStack w={'100%'} px={8}>
                                        {r.profile_picture ?
                                            (<Image
                                                src={r.profile_picture}
                                                width={'35px'}
                                                h={'35px'}
                                                border={'2px'}
                                                borderColor='primary.800'
                                                boxShadow={'lg'}
                                                borderRadius="50%"
                                            />)
                                            :
                                            <img src='../user_management.png' width={'38px'} alt='user' />
                                        }
                                        <VStack ml={1} spacing={0} alignItems={'start'}>
                                            <Text fontSize={'sm'}>{r.username}</Text>
                                            <Text fontSize={'xs'} color={'grey'}>{r.email}</Text>
                                        </VStack>
                                    </HStack>
                                </Card>
                            ))}
                        </VStack>
                    ) : (
                        <Text fontSize={'sm'} color={'black'} p={4}>No used Referral !</Text>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}




